<template>
  <b-row
    class="vh-100 px-0"
    style="position: relative; overflow: hidden; background-color: var(--iq-success-dark);"
  >
    <!-- <b-card
      class="border bg-pale-success"
      style="width: 1200px; height: 750px; position: absolute;  left: -600px; top: -550px; transform: rotate(45deg);"
    />
    <b-card
      class="border bg-success"
      style="width: 500px; height: 340px; position: absolute; left: -320px; top: 50px; transform: rotate(45deg);"
    />
    <b-card
      class="border bg-pale-warning"
      style="width: 600px; height: 350px; position: absolute;  left: -300px; top: -210px; transform: rotate(45deg);"
    />
    <b-card
      class="border border-success"
      style="width: 600px; height: 750px; position: absolute;  left: -300px; top: -210px; transform: rotate(45deg); background: transparent;"
    />
    <b-card
      class="border bg-success"
      style="width: 1100px; height: 750px; position: absolute;  right: -800px; bottom: -250px; transform: rotate(45deg);"
    />
    <b-card
      class="border bg-pale-success"
      style="width: 700px; height: 470px; position: absolute; right: -140px; bottom: -190px; transform: rotate(45deg);"
    />
    <b-card
      class="border border-warning"
      style="width: 600px; height: 750px; position: absolute;  right: -200px; bottom: -50px; transform: rotate(45deg); background: transparent;"
    /> -->
    <b-container class="h-100 d-flex flex-column justify-content-between align-items-center">
      <div class="w-100 text-right pt-5">
        <b-img
          src="https://lh3.googleusercontent.com/chat_attachment/AP1Ws4tz83JAp4COAeWq8UiUBcHiu9dkymzz06KUouJAau2Z4_TxgZ4M9izWvvuzEEj0sJ3mE8QS9h7MZMRebRmmqB7sWh6cTaT-Di9dXdEBdr9qqmZnMjKZxp7nsGWs4U5l6RKmf3qr--j0mK2dUES-PiTnoQOBNBgXbU-cwiyZtMWaOK21VDELPrcD2I5CPIU_f4xcR7WgjOOibhzUZR8nAPNoN_wLb_QVAukkvlzH2xu99nQewvNewBOphfFM5aIkQnff7owGEON9bQtMp17EcM7zXm-W1NNbo3lb4Y9z6L2frwl-OeFegoky5LTNwmkMj8k=w1920-h868"
          height="72"
        />
      </div>
      <div>

        <ValidationObserver
          ref="signinForm"
          v-slot="{ handleSubmit }"
        >
          <form
            role="form"
            @submit.prevent="handleSubmit(onSignIn)"
          >
            <b-card
              header-tag="header"
              footer-tag="footer"
              class="bg-white shadow-lg"
              text-variant="dark"
              style="max-width: 400px;"
            >
              <template #header>
                <h5 class="mb-0 text-center py-1 font-weight-bolder">
                  Integrated Finance Accounting System
                </h5>
              </template>
              <b-card-text class="pb-1">

                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="username"
                          name="username"
                          rules="required"
                        >
                          <b-form-group
                            label="Username"
                            label-for="username"
                            label-class="font-weight-bold"
                            class="mb-2"
                          >
                            <b-input
                              id="username"
                              v-model="credentials.username"
                              type="text"
                              placeholder="enter username here"
                              autocomplete="off"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="password"
                          name="password"
                          rules="required"
                        >
                          <b-form-group
                            label="Password"
                            label-for="password"
                            label-class="font-weight-bold"
                            class="mb-2"
                          >
                            <b-input-group
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                            >
                              <b-input
                                id="password"
                                v-model="credentials.password"
                                placeholder="enter password here"
                                autocomplete="off"
                                :type="passwordType"
                                :state="errors.length > 0 ? false : null"
                                :disabled="state.busy"
                              />
                              <b-input-group-append>
                                <b-button
                                  class="pl-2 pr-1"
                                  variant="warning"
                                  style="border-radius: 0px 5px 5px 0px"
                                  :disabled="state.busy"
                                  @click="togglePassword"
                                >
                                  <i
                                    v-if="state.visible_password"
                                    class="ri-eye-line h5"
                                  />
                                  <i
                                    v-else
                                    class="ri-eye-off-line h5"
                                  />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="px-2 pt-3">

                  <b-overlay
                    class="col-12 px-1"
                    rounded
                    opacity="0.1"
                    spinner-variant="warning"
                    spinner-small
                    :show="state.local"
                  >
                    <b-button
                      size="sm"
                      type="submit"
                      class="w-100"
                      variant="success"
                      :disabled="state.busy"
                    >
                      Sign In
                    </b-button>
                  </b-overlay>

                  <b-col class="text-divider">
                    <span>&nbsp; or sign in with</span>
                  </b-col>

                  <b-overlay
                    class="col-12 px-1"
                    rounded
                    opacity="0.1"
                    spinner-variant="success"
                    spinner-small
                    :show="state.sso"
                  >
                    <b-button
                      size="sm"
                      type="button"
                      class="w-100"
                      variant="warning"
                      :disabled="state.busy"
                      @click="onSignInWithAljaySSO"
                    >
                      Aljay SSO
                    </b-button>
                  </b-overlay>

                </b-row>
              </b-card-text>
            </b-card>

          </form>
        </ValidationObserver>
      </div>
      <div class="pb-5">
&nbsp;
      </div>
    </b-container>
  </b-row>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedAuthService, SharedSSOService } from '@/services'
import { Oauth2 } from 'universal-social-auth'
import url from '@/mixins/url'
import logos from '@/mixins/logos'

export default {
  name: 'SharedLogin',

  middleware: ['guest'],

  metaInfo: () => ({
    title: 'Sign In'
  }),

  mixins: [
    url,
    logos
  ],

  data: () => ({
    state: {
      busy: false,
      local: false,
      sso: false,
      visible_password: false
    },
    credentials: {
      username: null,
      password: null
    },
    Oauth2
  }),

  computed: {

    companyLogo () {
      return this.transformToImageUrl(this.getCompany())
    },

    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async saveAndFetchUser (token) {
      this.$store.dispatch('auth/saveToken', token)
      return new Promise((resolve, reject) => {
        SharedAuthService.FetchUser().then(
          ({ data }) => {
            this.$store.dispatch('auth/fetchUserSuccess', {
              id: data.id,
              email: data.email,
              role: data.role.code,
              role_name: data.role.name,
              username: data.role.username,
              name: data.name
            })
            const user = this.$store.getters['auth/user']
            this.$router.replace({
              name: user ? `${user.role}.dashboard` : 'dashboard'
            }, () => {
              resolve(data)
            })
          }
        ).catch(error => {
          this.$store.dispatch('auth/fetchUserFailure')
          reject(error)
        })
      })
    },

    async onSignIn () {
      await this.$refs.signinForm.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = this.state.local = true
            SharedAuthService.SignIn(this.credentials).then(
              ({ data: { token } }) => {
                this.saveAndFetchUser(token).finally(() => {
                  this.state.busy = this.state.local = false
                })
              }
            ).catch(() => {
              this.state.busy = this.state.local = false
            })
          }
        }
      )
    },

    async onSignInWithAljaySSO (event) {
      event.preventDefault()
      this.state.busy = this.state.sso = true
      this.$Oauth.authenticate('oauth2', this.Oauth2).then(
        authorized => {
          SharedSSOService.post(authorized).then(
            ({ data: { token } }) => {
              this.saveAndFetchUser(token).finally(() => {
                this.state.busy = this.state.local = false
              })
            }
          ).catch(() => {
            this.state.busy = this.state.sso = false
          })
        }
      )
    }
  }
}
</script>

<style scoped>

.text-divider {
  margin: 2em 0;
  line-height: 0;
  text-align: center;
  color: gray;
  padding: 0px 5px;
}
.text-divider span {
  background-color: rgb(255, 255, 255);
  padding: 0 0.5em;
}
.text-divider:before {
  content: " ";
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid #f7f7f7;
}
.text-divider span:before  {
  content: "";
  display: block;
  margin-top: -3px;
}
.text-divider span:after  {
  display: block;
  margin-top: -3px;
}
/* .font-cinzel-decorative {
  font-family: ;
} */
@font-face {
    font-family: 'Lato';
    src: url('/assets/lato/Lato-Black.ttf');
}
.ifas-text-logo {
  font-size: 30px;
  color: white;
  font-family: 'Lato';
  padding: 0px 10px;
}
.logo {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  height: 35vh;
}
.sign-in {
  border-radius: 70px 0px 0px 0px;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  height: 65vh;
}

@media screen and (orientation: landscape) {
  .logo {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    height: 100vh;
  }

  .sign-in {
    border-radius: 0px 0px 0px 0px;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    height: 100vh;
  }
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
  .sign-in {
    border-radius: 0px 0px 0px 0px;
    /* .welcome-back {
      display: none !important;
    } */
  }
}

@media screen and (min-width: 1024px) {

  .logo {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    height: 100vh;
  }

  .sign-in {
    border-radius: 0px 0px 0px 0px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    height: 100vh;
  }

}
</style>
